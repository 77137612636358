import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import UserDataService from 'src/app/sharedServices/user-data.service';

@Component({
  selector: 'app-prelogin-header',
  templateUrl: './prelogin-header.component.html',
  styleUrls: ['./prelogin-header.component.css']
})
export class PreloginHeaderComponent implements OnInit {
  userLang = '';
  showLangDropdown = false;
  showMenu = false;
  currentSection: string = '';

  constructor(
    public translate: TranslateService,
    private userDataService: UserDataService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    translate.addLangs(['EN', 'DE']);
    this.userLang = this.userDataService.getLanguage();
    if (!this.userLang) {
      this.userLang = 'EN';
      this.userDataService.setLanguage(this.userLang);
    }
    this.translate.setDefaultLang(this.userLang);
    this.translate.use(this.userLang);
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe((event: NavigationStart) => {
      if (!event.url.includes('/home')) {
        this.currentSection = '';
      }
    });

    this.activatedRoute.fragment.subscribe(fragment => {
      if (fragment) {
        setTimeout(() => {
          const element = document.getElementById(fragment);
          if (element) {
            window.scrollTo({
              top: element.offsetTop - 30, // Apply the offset
              behavior: 'smooth'
            });
          }
        }, 200);
      }
    });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    const sections = document.querySelectorAll('.scroll-section');
    sections.forEach(section => {
      const rect = section.getBoundingClientRect();
      const sectionId = section.id;
      if (rect.top >= 0 && rect.top <= window.innerHeight / 2) {
        this.currentSection = sectionId;
      }
    });
  }

  openLanguagePopup(): void {
    this.showLangDropdown = !this.showLangDropdown;
  }

  onLanguageSelect(language: string): void {
    this.showLangDropdown = false;
    this.userLang = language;
    this.translate.use(this.userLang);
    this.userDataService.setLanguage(this.userLang);
  }

  onClickOutsideLanguageBox(): void {
    this.showLangDropdown = false;
  }

  showHideMenu(): void {
    this.showMenu = !this.showMenu;
  }

  hideMenu(): void {
    this.showMenu = false;
  }

  onMenuClick(sectionId: string, scrollAdjustment?: number) {
    this.currentSection = sectionId;
    const element = document.getElementById(sectionId);
    if (element) {
      window.scrollTo({
        top: element.offsetTop - (scrollAdjustment ?? 30), // Apply the offset
        behavior: 'smooth'
      });
    }
    this.hideMenu();
  }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-session-warning-popup',
  templateUrl: './session-warning-popup.component.html',
  styleUrls: ['./session-warning-popup.component.css']
})
export class SessionWarningPopupComponent implements OnInit, OnChanges {
  @Input() countdown: number = 900;  // Countdown in seconds (15 minutes)
  countdownDisplay: string = '';
  timer: any;

  @Output() onLogoutUser = new EventEmitter();
  @Output() onContinueSession = new EventEmitter();

  ngOnInit(): void {
    this.startCountdown();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['countdown'].firstChange && changes['countdown'].currentValue) {
      clearInterval(this.timer);
      this.startCountdown();
    }
  }

  startCountdown() {
    this.updateDisplay();
    // Set an interval to update the countdown every second
    this.timer = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
        this.updateDisplay();
      } else {
        clearInterval(this.timer);
        this.onLogOut();
      }
    }, 1000);
  }

  updateDisplay() {
    // Convert the countdown from seconds to MM:SS format
    const minutes = Math.floor(this.countdown / 60);
    const seconds = this.countdown % 60;
    this.countdownDisplay = `${this.pad(minutes)}:${this.pad(seconds)}`;
  }

  pad(time: number): string {
    return time < 10 ? '0' + time : time.toString();
  }

  keepLoggedIn(): void {
    this.onContinueSession.emit();
    clearInterval(this.timer);
  }

  onLogOut(): void {
    this.onLogoutUser.emit();
  }
}

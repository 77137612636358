import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-home-new',
  templateUrl: './home-new.component.html',
  styleUrls: ['./home-new.component.css']
})
export class HomeNewComponent implements OnInit, AfterViewInit {
  isVideoPlaying = false;
  itemsPerSlide = 1;
  showCarousel = false;
  wineSlides = [
    {
      wineName: 'Château Margaux 2015',
      description: 'Limited edition, renowned for its rich flavors and long-lasting finish.',
      imagePath: '../../assets/homepage/wine-slider/wine-slide-1.png',
    },
    {
      wineName: '4 élements Pinot Noir Huré Frères',
      description: 'Limited edition, renowned for its rich flavors and long-lasting finish.',
      imagePath: '../../assets/homepage/wine-slider/wine-slide-2.png'
    },
    {
      wineName: 'Château Margaux 2017',
      description: 'Limited edition, renowned for its rich flavors and long-lasting finish.',
      imagePath: '../../assets/homepage/wine-slider/wine-slide-1.png',
    },
  ]
  wineArticles = [
    {
      title: 'Blog/Article Title and second line space',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt testing text here.',
      imagePath: '../../assets/homepage/images/home-banner.png',
    },
    {
      title: 'Blog/Article Title and second line space',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt testing text here.',
      imagePath: '../../assets/homepage/images/home-banner.png',
    },
    {
      title: 'Blog/Article Title and second line space',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt testing text here.',
      imagePath: '../../assets/homepage/images/home-banner.png',
    },
  ]
  faqList = [
    {
      question: 'What is TresorVino, and how does it work?',
      answer: 'TresorVino is a peer-to-peer marketplace for wine enthusiasts, connecting buyers and sellers directly. Start by adding wines to your Digital Cellar Book, list them on the Marketplace, and trade seamlessly using Vino Coins, our in-app currency. Whether you’re buying rare vintages, selling your treasures, or exploring wine as an investment, TresorVino makes it simple and rewarding.'
    },
    {
      question: 'What is TresorVino, and how does it work?',
      answer: 'TresorVino is a peer-to-peer marketplace for wine enthusiasts, connecting buyers and sellers directly. Start by adding wines to your Digital Cellar Book, list them on the Marketplace, and trade seamlessly using Vino Coins, our in-app currency. Whether you’re buying rare vintages, selling your treasures, or exploring wine as an investment, TresorVino makes it simple and rewarding.'
    },
    {
      question: 'What is TresorVino, and how does it work?',
      answer: 'TresorVino is a peer-to-peer marketplace for wine enthusiasts, connecting buyers and sellers directly. Start by adding wines to your Digital Cellar Book, list them on the Marketplace, and trade seamlessly using Vino Coins, our in-app currency. Whether you’re buying rare vintages, selling your treasures, or exploring wine as an investment, TresorVino makes it simple and rewarding.'
    },
    {
      question: 'What is TresorVino, and how does it work?',
      answer: 'TresorVino is a peer-to-peer marketplace for wine enthusiasts, connecting buyers and sellers directly. Start by adding wines to your Digital Cellar Book, list them on the Marketplace, and trade seamlessly using Vino Coins, our in-app currency. Whether you’re buying rare vintages, selling your treasures, or exploring wine as an investment, TresorVino makes it simple and rewarding.'
    }
  ]
  openFaqIndex = 0;

  options: AnimationOptions = {
    path: '/assets/homepage/at-a-glance/at-a-glance-desktop.json',
    renderer: 'svg'
  };
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 768 && this.itemsPerSlide === 3) {
      this.showCarousel = false;
      this.itemsPerSlide = 1;
      setTimeout(() => {
        this.showCarousel = true;
      }, 50);
    } else if (event.target.innerWidth >= 768 && this.itemsPerSlide === 1) {
      this.showCarousel = false;
      this.itemsPerSlide = 3;
      setTimeout(() => {
        this.showCarousel = true;
      }, 50);
    }
  }
  constructor(
    private titleService: Title,
    private metaService: Meta
  ) {
    this.titleService.setTitle('TresorVino - The Premier Platform for Fine Wine Trading and Investment');
    this.metaService.updateTag({ name: 'description', content: 'Join TresorVino, the ultimate destination for wine collectors and enthusiasts. Trade rare and fine wines, manage your collection with ease, and connect with a global community of connoisseurs. Explore curated selections, track wine investments, and earn rewards with Vino Coins.' });
  }

  ngOnInit(): void {
    if (window.innerWidth >= 768) {
      this.itemsPerSlide = 3;
    }
    this.showCarousel = true;
  }

  ngAfterViewInit() {
    // Create an IntersectionObserver instance
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const video: HTMLVideoElement = document.getElementById("home-video") as any;

          if (entry.isIntersecting || video.paused) {
            // Play video when section comes into view
            video.play();
            this.isVideoPlaying = true;
          }
        });
      },
      {
        threshold: 0.5 // Play when at least 50% of the section is in view
      }
    );

    // Observe the section with the video
    const videoSection = document.querySelector('.video-container');
    if (videoSection) {
      observer.observe(videoSection);
    }
  }

  togglePlayPause(): void {
    const video = document.getElementById("home-video") as any;
    // const playPauseButton = document.querySelector(".play-pause-button");
    if (video.paused) {
      video.play();
      this.isVideoPlaying = true;
      // playPauseButton.textContent = "Pause";
    } else {
      video.pause();
      // playPauseButton.textContent = "Play";
    }
  }

  openAccordion(index: number): void {
    if (this.openFaqIndex === index) {
      this.openFaqIndex = -1;
    } else {
      this.openFaqIndex = index;
    }
  }

}

<header class="pre-login-header navbar navbar-expand-md">
    <div class="container-fluid">
        <a class="navbar-brand" href="/home">
            <img src="../../../../assets/img/TresorVino-Logo-white.png" alt="Tresorvino Logo">
        </a>
        <button class="navbar-toggler" type="button" (click)="showHideMenu()" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <mat-icon>menu</mat-icon>
        </button>
        <div class="collapse navbar-collapse justify-content-md-center mobile-menu" id="navbarNav" [ngClass]="{'show': showMenu}">
            <ul class="navbar-nav home-page-menu">
                <li class="nav-item d-md-none" (click)="hideMenu()">
                    <a class="nav-link" aria-current="page" routerLink="/login" routerLinkActive="active">Login</a>
                </li>
                <li class="nav-item d-md-none" (click)="hideMenu()">
                    <a class="nav-link" aria-current="page" routerLink="/registration" routerLinkActive="active">Sign Up</a>
                </li>
                <li class="nav-item" (click)="onMenuClick('how-it-works', -15)">
                    <a class="nav-link" [ngClass]="{'active': currentSection === 'how-it-works'}" routerLink="/home" fragment="how-it-works">How it Works</a>
                </li>
                <li class="nav-item" (click)="onMenuClick('features', -20)">
                    <a class="nav-link" [ngClass]="{'active': currentSection === 'features'}" routerLink="/home" fragment="features">Features</a>
                </li>
                <li class="nav-item" (click)="onMenuClick('community', -170)">
                    <a class="nav-link" [ngClass]="{'active': currentSection === 'community'}" routerLink="/home" fragment="community">Community</a>
                </li>
                <li class="nav-item" (click)="onMenuClick('faq')">
                    <a class="nav-link" [ngClass]="{'active': currentSection === 'faq'}" routerLink="/home" fragment="faq">FAQ</a>
                </li>
                <li class="nav-item d-md-none">
                    <mat-form-field appearance="fill" class="white-field language-selection">
                        <mat-label>Language</mat-label>
                        <mat-select class="white-field" (selectionChange)="onLanguageSelect($event.value)" [(ngModel)]="userLang">
                            <mat-option value="EN">
                                <span class="flag mr-2">
                                    <img src="../../../../assets/homepage/flags/english-flag.png">
                                </span>
                                <span class="text">EN</span>
                            </mat-option>
                            <mat-option value="DE">
                                <span class="flag mr-2">
                                    <img src="../../../../assets/homepage/flags/german-flag.png">
                                </span>
                                <span class="text">DE</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </li>
            </ul>
        </div>
        <button type="button" class="btn tr-btn-primary mr-3 mr-lg-5 btn-registration" [routerLink]="['/registration']">Sign Up</button>
        <div class="right-side-menu">
            <ul class="navbar-nav">
                <li class="nav-item translation-icon">
                    <span class="nav-link">
                        <img src="../../../../assets/icons/translation-icon.png" (click)="openLanguagePopup()" alt="Translation Icon">
                    </span>
                    <div class="language-dropdown" [ngClass]="{'d-none': !showLangDropdown }" (clickOutside)="onClickOutsideLanguageBox()" [exclude]="'.translation-icon'">
                        <div class="language" [ngClass]="{'active': userLang === 'EN'}" (click)="onLanguageSelect('EN')">
                            <span class="flag">
                                <img src="../../../../assets/homepage/flags/english-flag.png">
                            </span>
                            <span class="text">EN</span>
                        </div>
                        <div class="language" [ngClass]="{'active': userLang === 'DE'}" (click)="onLanguageSelect('DE')">
                            <span class="flag">
                                <img src="../../../../assets/homepage/flags/german-flag.png">
                            </span>
                            <span class="text">DE</span>
                        </div>
                    </div>
                </li>
                <li class="nav-item user-icon">
                    <a class="nav-link" routerLink="/login">
                        <img src="../../../../assets/icons/user-icon.png" alt="User Icon">
                    </a>
                </li>
            </ul>
        </div>
    </div>
</header>

<div class="home-container">
    <section class="section-one">
        <div class="background-image">
            <img class="home-banner d-none d-sm-block" src="../../assets/homepage/images/home-banner.png" alt="A table set with red wine glasses, wine bottles, and fresh grapes in a luxurious setting with purple accents." title="Luxurious Wine Table with Grapes and Bottles">
            <img class="home-banner d-sm-none" src="../../assets/homepage/images/home-banner-mobile.png" alt="A table set with red wine glasses, wine bottles, and fresh grapes in a luxurious setting with purple accents." title="Luxurious Wine Table with Grapes and Bottles">
        </div>
        <div class="banner-text">
            <h1 class="heading mb-3">
                Discover. Trade. Connect.
                <br>
                <i>A Wine Marketplace for all.</i>
            </h1>
            <p class="description mb-4">TresorVino is a people-to-people platform that connects wine enthusiasts from around the world. Buy, sell, and showcase your wine collection in a trusted digital marketplace where everyone can participate, regardless of experience.</p>
            <button type="button" class="btn tr-btn-primary btn-signup" routerLink="/registration">Sign Up for Free</button>
        </div>
    </section>
    <section class="section-two">
        <div class="wine-slider-container container">
            <h4 class="heading primary-color">Finest Wine offers on Tresorvino Marketplace</h4>
            <p class="description">Discover a curated selection of rare and fine wines available for trade on our marketplace. Each bottle is handpicked for quality and exclusivity, making it the perfect addition to any collection.</p>
            <carousel [itemsPerSlide]="itemsPerSlide" [showIndicators]="false" *ngIf="showCarousel">
                <slide *ngFor="let slide of wineSlides;">
                    <div class="wine-slide">
                        <div class="card-background">
                            <div class="wine-image">
                                <img [src]="slide.imagePath">
                            </div>
                            <div class="wine-details">
                                <span class="wine-name primary-color">{{ slide.wineName }}</span>
                                <span class="wine-description">{{ slide.description }}</span>
                                <div class="button-container">
                                    <button type="button" class="btn tr-btn-primary">Buy Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </slide>
            </carousel>
        </div>
    </section>
    <section class="section-three scroll-section" id="how-it-works">
        <div class="container">
            <h2 class="primary-color font-bold m-0">How TresorVino Helps You Level Up Your Wine Collection</h2>
            <img class="desktop-gif d-none d-md-block" src="../../assets/homepage/at-a-glance/at-a-glance-desktop.gif" alt="Visual diagram showing a user interacting with features like the Cellar Book, Dashboard, Marketplace, and Community, connected to Vino Coins for completing tasks, leveling up, and viewing bids and market prices." title="User Workflow and Earning Vino Coins on TresorVino">
            <img class="mobile-gif d-md-none" src="../../assets/homepage/at-a-glance/at-a-glance-mobile.gif" alt="Visual diagram showing a user interacting with features like the Cellar Book, Dashboard, Marketplace, and Community, connected to Vino Coins for completing tasks, leveling up, and viewing bids and market prices." title="User Workflow and Earning Vino Coins on TresorVino">
        </div>
    </section>
    <section class="section-four">
        <div class="container">
            <div class="video-container text-center">
                <div class="position-relative">
                    <video id="home-video" loop>
                        <source src="../../assets/homepage/vino-coins/vino-coins.mp4" type="video/mp4">
                    </video>
                    <div class="pause-overlay" *ngIf="!isVideoPlaying"></div>
                    <button type="button" class="play-pause-button" (click)="togglePlayPause()" *ngIf="!isVideoPlaying">
                        <img class="play-icon" src="../../assets/icons/play-icon.png">
                    </button>
                </div>
                <h4 class="mt-4">Ready to join the wine collectors community?</h4>
                <p>Join now and experience wine trading like never before!</p>
                <button type="button" class="btn tr-btn-primary" routerLink="/registration">Get Started</button>
            </div>
        </div>
    </section>
    <section class="section-five scroll-section" id="features">
        <div class="container">
            <!-- <ng-lottie [options]="options" width="400"></ng-lottie> -->
            <h2 class="primary-color heading">Why Choose TresorVino?</h2>
            <p class="description">TresorVino offers a complete wine investing experience, from trading and tracking to connecting with other wine enthusiasts.</p>
            <div class="features-container">
                <div class="feature">
                    <div class="feature-info">
                        <h3 class="feature-title">Personalised Dashboard for Wine Collection Management</h3>
                        <p class="feature-description">Track, organise, and showcase your wines in your personal Digital Cellar Book. Whether you’re buying, selling, or simply keeping tabs, our tools make managing your wine collection seamless.</p>
                    </div>
                    <div class="feature-video">
                        <div class="sticky-container">
                            <img class="d-none d-md-block" src="../../assets/homepage/dashboard/dashboard-desktop.gif" alt="A dashboard showing the performance of all wine cellar books, including investment value, current value, total return, and a visual donut chart of wine types." title="Performance Overview of All Wine Cellar Books">
                            <img class="d-md-none" src="../../assets/homepage/dashboard/dashboard-mobile.gif" alt="A dashboard showing the performance of all wine cellar books, including investment value, current value, total return, and a visual donut chart of wine types." title="Performance Overview of All Wine Cellar Books">
                        </div>
                    </div>
                </div>
                <div class="feature">
                    <div class="feature-info">
                        <h3 class="feature-title">Earn and Spend Vino Coins</h3>
                        <p class="feature-description">Vino Coins, our in-app currency, unlock a world of possibilities. Use them to access premium features, trade wines, and enjoy exclusive marketplace benefits.</p>
                    </div>
                    <div class="feature-video">
                        <div class="sticky-container">
                            <img class="d-none d-md-block" src="../../assets/homepage/vino-coins/vino-coins-desktop.gif" alt="Interface showing an option to unlock bids with 30 Vino Coins, alongside tasks like adding wines to the Cellar Book, buying from the marketplace, and posting wines to earn more Vino Coins." title="Unlock Bids and Earn Vino Coins on TresorVino">
                            <img class="d-md-none" src="../../assets/homepage/vino-coins/vino-coins-mobile.gif" alt="Interface showing an option to unlock bids with 30 Vino Coins, alongside tasks like adding wines to the Cellar Book, buying from the marketplace, and posting wines to earn more Vino Coins." title="Unlock Bids and Earn Vino Coins on TresorVino">
                        </div>
                    </div>
                </div>
                <div class="feature">
                    <div class="feature-info">
                        <h3 class="feature-title">Extensive Wine Database to Aid Decisions</h3>
                        <p class="feature-description">With a database of over 12,000 wines, TresorVino provides detailed wine profiles, market trends, and transparent price benchmarks. Our platform offers up-to-date market prices to help you make informed buying and selling decisions with confidence.</p>
                    </div>
                    <div class="feature-video">
                        <div class="sticky-container">
                            <img class="d-none d-md-block" src="../../assets/homepage/wine-database/wine-database-desktop.gif" alt="Detailed wine listing for Mont Redon Châteauneuf-du-Pape, featuring producer information, market price, quantity, and selling options." title="Mont Redon Châteauneuf-du-Pape Wine Listing">
                            <img class="d-md-none" src="../../assets/homepage/wine-database/wine-database-mobile.gif" alt="Detailed wine listing for Mont Redon Châteauneuf-du-Pape, featuring producer information, market price, quantity, and selling options." title="Mont Redon Châteauneuf-du-Pape Wine Listing">
                        </div>
                    </div>
                </div>
                <div class="feature">
                    <div class="feature-info">
                        <h3 class="feature-title">Marketplace for People-to-People Trading</h3>
                        <p class="feature-description">A peer-to-peer platform where wine enthusiasts of all levels can meet, trade, and grow their collections. Whether you’re buying or selling, TresorVino connects you with like-minded collectors.</p>
                    </div>
                    <div class="feature-video">
                        <div class="sticky-container">
                            <img class="d-none d-md-block" src="../../assets/homepage/p2p/p2p-desktop.gif" alt="A visual representation of users with different levels and badges, along with wine trading options like ‘Bid’ and ‘Sell’ highlighted below." title="User Levels and Wine Trading Options">
                            <img class="d-md-none" src="../../assets/homepage/p2p/p2p-mobile.gif" alt="A visual representation of users with different levels and badges, along with wine trading options like ‘Bid’ and ‘Sell’ highlighted below." title="User Levels and Wine Trading Options">
                        </div>
                    </div>
                </div>
                <div class="feature">
                    <div class="feature-info">
                        <h3 class="feature-title">Connect with a Global Wine Community</h3>
                        <p class="feature-description">Join a global wine community to show off your Digital Cellar Book, exchange insights, and share your passion for wine with fellow collectors. TresorVino brings together private collectors and enthusiasts in a vibrant community.</p>
                    </div>
                    <div class="feature-video">
                        <div class="sticky-container">
                            <img class="d-none d-md-block" src="../../assets/homepage/community/global-community-desktop.gif" alt="A private wine community group interface showing members and a discussion post about the best places to learn about wine making." title="Community Group for Wine Enthusiasts in Berlin">
                            <img class="d-md-none" src="../../assets/homepage/community/global-community-mobile.gif" alt="A private wine community group interface showing members and a discussion post about the best places to learn about wine making." title="Community Group for Wine Enthusiasts in Berlin">
                        </div>
                    </div>
                </div>
                <div class="feature asset-class">
                    <div class="feature-info">
                        <h3 class="feature-title">Wine as an Asset Class</h3>
                        <p class="feature-description">Turn your passion into profit by exploring wine as an investment. TresorVino’s platform helps you unlock the true value of your collection by connecting you with serious buyers and providing the tools to trade confidently.</p>
                    </div>
                    <div class="feature-video">
                        <div class="sticky-container">
                            <img class="d-none d-md-block" src="../../assets/homepage/asset-class/asset-class-desktop.gif" alt="Screenshot of a Digital Cellar Book interface showing detailed wine profiles, prices, and purchase history." title="Digital Cellar Book for Managing Wine Collections">
                            <img class="d-md-none" src="../../assets/homepage/asset-class/asset-class-mobile.gif" alt="Screenshot of a Digital Cellar Book interface showing detailed wine profiles, prices, and purchase history." title="Digital Cellar Book for Managing Wine Collections">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="section-six">
        <div class="position-relative">
            <div class="vinocoin-wave-banner"></div>
            <div class="content-wrapper d-flex flex-column flex-md-row">
                <div class="coin-pyramid">
                    <img src="../../assets/homepage/images/vino-coin-image.png" alt="A pyramid of Vino Coins with one coin prominently highlighted, symbolizing the in-app currency used for trading and rewards on the TresorVino platform." title="Vino Coins Highlight on TresorVino Platform">
                </div>
                <div class="pyramid-details">
                    <h5>Level Up and Earn Free Vino Coins</h5>
                    <p>Earn rewards as you actively engage with the platform—gain free Vino Coins by contributing, referring friends, and progressing through the ranks!</p>
                    <button class="btn tr-btn-primary" routerLink="/registration">Sign up for free</button>
                </div>
            </div>
        </div>
        <div class="community-banner scroll-section" id="community">
            <div class="container d-flex flex-column flex-md-row">
                <div class="left-side">
                    <img class="star" src="../../assets/img/level-star.svg">
                    <h2>Join a Global Community for Wine Enthusiasts</h2>
                    <p class="text-one secondary-color">Be part of a community where wine collectors share insights, reviews, and tips on wine investing. From cellar stories to advice on rare wines, TresorVino is the ultimate hub for wine enthusiasts.</p>
                    <div class="divider-line"></div>
                    <div class="text-two">Ready to elevate your wine collection? <br><span class="secondary-color"> Join TresorVino today and experience wine trading like never before.</span></div>
                    <button class="btn tr-btn-primary" routerLink="/registration">Get Started</button>
                </div>
                <div class="right-side">
                    <img class="image-two d-block d-md-none" src="../../assets/homepage/images/community-two.png">
                    <img class="image-one" src="../../assets/homepage/images/community-one.png">
                    <img class="image-two d-none d-md-block" src="../../assets/homepage/images/community-two.png">
                </div>
            </div>
            <div class="lower-rectangle">
                <img src="../../assets/homepage/images/community-rectangle.png" alt="rectangle">
            </div>
        </div>

    </section>
    <section class="section-seven">
        <div class="articles-slider-container container">
            <h4 class="heading primary-color text-center">Check Out Some Interesting Reads</h4>
            <carousel [itemsPerSlide]="itemsPerSlide" [showIndicators]="false" *ngIf="showCarousel">
                <slide *ngFor="let article of wineArticles;">
                    <div class="article-slide">
                        <div class="card-background">
                            <div class="article-image">
                                <img [src]="article.imagePath">
                            </div>
                            <div class="article-details">
                                <span class="article-name primary-color">{{ article.title }}</span>
                                <span class="article-description">{{ article.description }}</span>
                                <div class="button-container">
                                    <button type="button" class="btn tr-btn-primary">Read</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </slide>
            </carousel>
        </div>
    </section>
    <section class="section-eight scroll-section" id="faq">
        <div class="faq-wave-banner"></div>
        <div class="faq-section">
            <div class="container">
                <h2 class="heading">Any questions? <i class="secondary-color">We got you.</i></h2>
                <div class="faq" *ngFor="let faq of faqList; let i = index;">
                    <div class="question">
                        <div class="text">{{ faq.question }}</div>
                        <div class="icon" (click)="openAccordion(i)">
                            <mat-icon *ngIf="openFaqIndex !== i">add_2</mat-icon>
                            <mat-icon *ngIf="openFaqIndex === i">remove</mat-icon>
                        </div>
                    </div>
                    <div class="answer secondary-color" *ngIf="openFaqIndex === i">{{ faq.answer }}</div>
                </div>
                <div class="button-container text-center">
                    <button type="button" class="btn tr-btn-primary" routerLink="/faqs">View More</button>
                </div>
            </div>
        </div>
    </section>
</div>
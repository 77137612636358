<div class="container" >
  <div class="login-wrapper" *ngIf="showLogin">
    <div class="normal-login mb-2">
      <div class="text-right">
        <a class="btn btn-link text-uppercase" data-toggle="tab" routerLink="/registration">{{ 'common.register' | translate }}</a>
      </div>
      <form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()">
        <div class="form-group">
          <mat-form-field appearance="fill">
            <mat-label for="email">{{ 'login.login_form_email' | translate }}</mat-label>
            <input matInput formControlName="email">
            <mat-error *ngIf="submitted && logInDetails.email.errors" class="error-msg">
              <div *ngIf="logInDetails.email.errors.required">{{ 'login.email_required_warning' | translate }}</div>
              <div *ngIf="logInDetails.email.errors.email">{{ 'login.valid_email_msg' | translate }}</div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field appearance="fill">
            <mat-label for="email">{{ 'login.password' | translate }}</mat-label>
            <input matInput type="password" formControlName="password">
            <mat-error *ngIf="submitted && logInDetails.password.errors" class="error-msg">
              <div *ngIf="logInDetails.password.errors.required">{{ 'login.password_required_warning' | translate }}</div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="text-center">
          <button type="submit" class="btn tr-btn-primary login-button">{{ 'login.login' | translate }}</button>
        </div>
      </form>
    </div>
    <div class="text-right">
      <a class="btn btn-link" data-toggle="tab" routerLink="/forgot-password">{{ 'login.forgot_password?' | translate }}</a>
    </div>
    <div class="social-login d-flex">
      <button type="button" class="btn tr-btn-primary mb-10" (click)="googleSignIn()">{{ 'login.connect_with_google' | translate }}</button>
      <!-- <button type="button" class="btn tr-btn-primary" (click)="fbSignIn()">{{ 'login.connect_with_facebook' | translate }}</button> -->
    </div>
    <!-- <div class="social-login text-center">
      <button type="button" class="btn tr-btn-primary mr-4" (click)="googleSignIn()">{{ 'login.connect_with_google' | translate }}</button>
      <button type="button" class="btn tr-btn-primary" (click)="fbSignIn()">{{ 'login.connect_with_facebook' | translate }}</button>
    </div> -->
</div>
</div>

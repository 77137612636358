<ng-adsense [adClient]="'ca-pub-1115035479249914'" [pageLevelAds]="true"></ng-adsense>
<div class="tr-main-wrapper" [ngClass]="{ 'public-theme': !authStatus }">
  <app-prelogin-header *ngIf="!authStatus"></app-prelogin-header>
  <header *ngIf="viewLoaded && authStatus">
    <app-header></app-header>
  </header>
  <main [ngClass]="{ 'public-page-container': !authStatus || !viewLoaded }" *ngIf="viewLoaded">
    <div class="page-container" [ngClass]="{'mb-0': noMarginBottom}">
      <router-outlet></router-outlet>
    </div>
  </main>
  <app-footer></app-footer>
  <app-share *ngIf="authStatus"></app-share>
  <app-toast></app-toast>
  <app-cookie-consent></app-cookie-consent>
  <app-level-up [userlevelData]="levelUpData" [allLevelsData]="levelsData" (onClosePopup)="closeLevelUpPopup()" *ngIf="showLevelUpPopup"></app-level-up>
  <app-vino-coins (onClosePopup)="closeVinoCoinPopup()" *ngIf="showVinoPopup"></app-vino-coins>
  <app-session-warning-popup [countdown]="countDownTimeSec" (onContinueSession)="keepLoggedIn()" (onLogoutUser)="onLogOut()" *ngIf="showSessionModal"></app-session-warning-popup>
</div>

import { gql } from 'apollo-angular';

// Kellerbook related queries
export const getUserKellerBook = gql`
query MyQuery {
  getOwnUserCellars {
    cellar_bk_df
    cellar_bk_id
    cellar_bk_nm
    cellar_bk_typ
    totalCellarWines
  }
}`;

export const getFriendsKellerBook = gql`
query MyQuery($user_id: ID!) {
  getUserCellars(user_id: $user_id) {
    cellar_bk_crtd_time
    cellar_bk_df
    cellar_bk_id
    cellar_bk_nm
    cellar_bk_typ
    user_id
    cellar_bk_updt_time
  }
}`;

// Wine related queries
export const getOwnCellarWines = gql`
query MyQuery($cellar_bk_id: ID!) {
  getOwnCellarWines(cellar_bk_id: $cellar_bk_id) {
    bought_on_tresorvino
    cw_deletion_status
    cellar_bk_id
    cellar_wine_df
    cw_available_qty
    cw_on_sale_qty
    cw_sold_qty
    cw_bottle_size
    cw_crtd_time
    cw_currency
    cw_image
    cw_lid
    cw_lid_qlty
    cw_liquid_lvl
    cw_packaging
    cw_price
    cw_quantity
    cw_storage
    cw_storage_temp
    cw_updt_time
    cw_vintage
    cw_ask_price
    cw_bid_price
    cw_description
    user_wine_id
    user_id
    user {
      picture
      lastname
      id
      identityId
      firstname
      error
    }
    global_wine_id
    cw_vino_status
    market_price {
      average
      day
      difference
      status
      relDiff
    }
    global_wine {
      add_info1
      add_info2
      add_info3
      barrel
      color
      country
      cultivation
      currency
      date_added
      date_updated
      flavor
      format
      grape_variety
      id
      label
      label_quality
      lid
      lid_quality
      liquid_level
      location
      name
      searchname
      packaging
      picture
      predicate_de
      predicate_fr
      producer_name
      producer_title
      production_quantity
      quantity
      region
      seller
      status
      storage
      soil
      storage_temperature
      sub_region
      sub_type
      type
      vineyard
      vintage {
        final
        first
      }
      wine_type
      current_price {
        prices {
          bottle_size
          currency
          domain
          likelihood
          region
          vintage
          wine_name
          wine_price
          wine_tax
          wine_url
        }
        average
        day
        difference
        relDiff
        status
      }
    }
    statistics {
      absDifference
      currentValue
      investmentValue
      relDifference
    }
  }
}`;

export const getWineListQuery = gql`
query MyQuery {
  getAllWines{
    nextToken
    wines {
      id
      color
      name
      country
      cultivation
      currency
      acquisition_price
      current_price
      format
      grape_variety
      label
      label_quality
      lid
      lid_quality
      liquid_level
      location
      packaging
      predicate_de
      predicate_fr
      producer_name
      production_quantity
      quantity
      region
      seller
      soil
      storage_temperature
      storage
      sub_type
      type
      vintage {
        first
        final
      }
      cw_currency
      cw_bottle_size
      cw_image
    }
  }
}`;

export const getWineByNameQuery = gql`
  query MyQuery($name: String!){
    searchWinesByName(name: $name){
      add_info1
      add_info2
      add_info3
      barrel
      color
      country
      cultivation
      currency
      date_added
      date_updated
      flavor
      format
      grape_variety
      id
      label
      label_quality
      lid
      lid_quality
      liquid_level
      name
      searchname
      location
      packaging
      picture
      predicate_de
      predicate_fr
      producer_name
      producer_title
      production_quantity
      quantity
      region
      seller
      soil
      status
      storage
      storage_temperature
      sub_region
      type
      sub_type
      vineyard
      vintage {
        first
        final
      }
      wine_type
    }
}`;

export const getFriendsKellerWineQuery = gql`
query MyQuery($cellar_bk_id: ID!) {
  getCellarWines(cellar_bk_id: $cellar_bk_id) {
    cw_bid_price
    cw_ask_price
    cellar_bk_id
    cellar_wine_df
    cw_bottle_size
    cw_crtd_time
    cw_currency
    cw_image
    cw_lid
    cw_lid_qlty
    cw_liquid_lvl
    cw_packaging
    cw_price
    cw_quantity
    cw_available_qty
    cw_storage
    cw_storage_temp
    cw_updt_time
    cw_vintage
    cw_description
    global_wine_id
    user_id
    user_wine_id
    user {
      error
      firstname
      id
      identityId
      lastname
      picture
    }
    global_wine {
      add_info1
      add_info2
      add_info3
      barrel
      color
      country
      cultivation
      currency
      date_added
      date_updated
      flavor
      format
      grape_variety
      id
      label
      label_quality
      lid
      lid_quality
      liquid_level
      location
      name
      packaging
      picture
      predicate_de
      predicate_fr
      producer_name
      producer_title
      production_quantity
      quantity
      region
      seller
      soil
      status
      storage
      storage_temperature
      sub_region
      sub_type
      type
      vineyard
      wine_type
      vintage {
        final
        first
      }
      current_price {
        prices {
          wine_url
          wine_tax
          wine_price
          wine_name
          region
          domain
          currency
        }
        average
        day
        status
      }
    }
  }
}`;

// Notifications related queries
export const getUnreadNotificationsQuery = gql`
query MyQuery($count: Int, $nextToken: String) {
  getNotificationPaginated(nextToken: $nextToken, count: $count) {
    nextToken
    notifications {
      user_id
      invoker_user_id
      event_id
      category
      created_timestamp
      payload {
        cellar_bk_id
        cellar_bk_nm
        user_wine_id
        wine_name
        community_id
        community_name
        post_id
        comment_id
        sales_id
        bid_detail_id
        sell_price
      }
      invoker_user_info {
        userType
        companyName
        vatNumber
        picture
        lastname
        id
        identityId
        firstname
        error
      }
    }
  }
}`;

export const getFriendsCount = gql`
query MyQuery {
  getFriends {
    totalFriends
  }
}`;

export const getCommunitiesCount = gql`
query MyQuery {
  getOwnCommunities {
    totalCommunities
  }
}`;

export const getDashboardLineChart = gql`
query MyQuery($cellar_bk_id: String) {
  getDashboard(cellar_bk_id: $cellar_bk_id) {
    history {
      sumInvestmentValue
      sumCurrentValue
      day_timestamp
      count
      cellar_bk_id
    }
  }
}
`;

export const getDashboard = gql`
query MyQuery($cellar_bk_id: String) {
  getDashboard(cellar_bk_id: $cellar_bk_id) {
    highestGlobalWine {
      wine {
        name
        current_price {
          prices {
            currency
            wine_price
          }
        }
        vintage {
          final
          first
        }
      }
      difference
      average
    }
    highestGlobalWineJump {
      wine {
        name
        current_price {
          prices {
            currency
            wine_price
          }
        }
        vintage {
          final
          first
        }
      }
      difference
      average
    }
    highestOwnWine {
      wine {
        name
        current_price {
          prices {
            currency
            wine_price
          }
        }
        vintage {
          final
          first
        }
      }
      difference
      average
    }
    highestOwnWineJump {
      wine {
        name
        current_price {
          prices {
            currency
            wine_price
          }
        }
        vintage {
          final
          first
        }
      }
      difference
      average
    }
    lowestGlobalWine {
      wine {
        name
        current_price {
          prices {
            currency
            wine_price
          }
        }
        vintage {
          final
          first
        }
      }
      difference
      average
    }
    lowestGlobalWineJump {
      wine {
        name
        current_price {
          prices {
            currency
            wine_price
          }
        }
        vintage {
          final
          first
        }
      }
      difference
      average
    }
    lowestOwnWine {
      wine {
        name
        current_price {
          prices {
            currency
            wine_price
          }
        }
        vintage {
          final
          first
        }
      }
      difference
      average
    }
    lowestOwnWineJump {
      wine {
        name
        current_price {
          prices {
            currency
            wine_price
          }
        }
        vintage {
          final
          first
        }
      }
      difference
      average
    }
    totalPendingFriends
    totalFriends
    totalOwnCommunities
    totalJoinedCommunities
    regions {
      count
      key
    }
    types {
      key
      count
    }
    history {
      sumInvestmentValue
      sumCurrentValue
      day_timestamp
      count
      cellar_bk_id
    }
  }
}
`;

export const getCurrentUserSales = gql`
query MyQuery {
  getCurrentUserSales {
    distance
    cellar_bk_id
    settle_status
    cellar_bk {
      cellar_bk_crtd_time
      cellar_bk_df
      cellar_bk_id
      cellar_bk_nm
      cellar_bk_typ
      cellar_bk_updt_time
    }
    sale_id
    so_ask_price
    so_per_bottle_price
    so_highest_bid
    so_num_bids
    so_quantity
    so_status
    so_crtd_date
    so_updt_date
    so_crtd_time
    so_updt_time
    user_id
    user_wine_id
    so_vino_status
    user {
      error
      firstname
      id
      identityId
      lastname
      picture
    }
    cellar_wine {
      cellar_bk_id
      cw_bid_price
      cw_crtd_time
      cw_currency
      cw_image
      cw_lid
      cw_lid_qlty
      cw_liquid_lvl
      cw_packaging
      cw_price
      cw_quantity
      cw_storage
      cw_storage_temp
      cw_vintage
      cw_updt_time
      cw_description
      cellar_wine_df
      cw_ask_price
      cw_bottle_size
      global_wine_id
      user_id
      user_wine_id
      cw_vino_status
      market_price {
        average
        day
        difference
        relDiff
        status
      }
      global_wine {
        add_info1
        add_info2
        add_info3
        barrel
        color
        country
        cultivation
        currency
        date_added
        date_updated
        flavor
        format
        grape_variety
        id
        label
        label_quality
        lid_quality
        lid
        liquid_level
        location
        name
        packaging
        picture
        predicate_de
        predicate_fr
        producer_name
        producer_title
        production_quantity
        quantity
        region
        seller
        soil
        status
        storage
        storage_temperature
        sub_region
        sub_type
        type
        vineyard
        vintage {
          final
          first
        }
        wine_type
        current_price {
          average
          day
          difference
          relDiff
          status
          prices {
            bottle_size
            currency
            domain
            fetch_time
            likelihood
            region
            request_id
            vintage
            wine_name
            wine_price
            wine_tax
            wine_url
          }
        }
      }
      statistics {
        absDifference
        currentValue
        investmentValue
        relDifference
      }
    }
  }
}
`

export const getSalesBid = gql`
query MyQuery ($selectedWineSaleId: ID!) {
  getSalesBid(sale_id: $selectedWineSaleId) {
    sale_deletion_status
    cellar_bk_id
    error
    global_wine_id
    sale_id
    seller_user_id
    so_quantity
    so_status
    user_wine_id
    bids {
      bd_ask_price
      bd_bid_price
      bd_crtd_time
      bd_quantity
      bd_status
      bd_updt_time
      bid_detail_id
      buyer_user_id
      cellar_bk_id
      sale_offer_id
      seller_user_id
      user_wine_id
      buyer_user_information {
        address
        error
        firstname
        id
        lastname
        mailID
        phone_number
        picture
        identityId
        companyName
        vatNumber
        userType
        user_level {
          user_level
        }
      }
      seller_user_id
      seller_user_information {
        address
        error
        firstname
        id
        lastname
        phone_number
        picture
        mailID
        identityId
        companyName
        vatNumber
        userType
      }
    }
    cellar_wine {
      cellar_bk_id
      cellar_wine_df
      cw_ask_price
      cw_bid_price
      cw_bottle_size
      cw_crtd_time
      cw_currency
      cw_image
      cw_lid
      cw_lid_qlty
      cw_liquid_lvl
      cw_packaging
      cw_price
      cw_quantity
      cw_storage
      cw_storage_temp
      cw_updt_time
      cw_vintage
      cw_description
      global_wine {
        add_info1
        add_info2
        add_info3
        barrel
        color
        country
        cultivation
        currency
        date_added
        date_updated
        flavor
        format
        grape_variety
        id
        label
        label_quality
        lid
        lid_quality
        liquid_level
        location
        name
        packaging
        picture
        predicate_de
        predicate_fr
        producer_name
        wine_type
        vineyard
        type
        sub_type
        sub_region
        storage_temperature
        storage
        status
        soil
        seller
        region
        producer_title
        production_quantity
        quantity
        vintage {
          final
          first
        }
        current_price {
          average
          day
          difference
          relDiff
          status
        }
      }
      global_wine_id
      user_id
      user_wine_id
      user {
        error
        firstname
        id
        identityId
        lastname
        picture
        companyName
        vatNumber
        userType
      }
      statistics {
        absDifference
        currentValue
        investmentValue
        relDifference
      }
    }
  }
}
`;

export const getAllOffers = gql`
  query MyQuery($distance: String!, $pincode: String!, $country: String, $itemsPerPage: String!, $pageNumber: String!) {
    getAllSales(distance: $distance, pincode: $pincode, country: $country, itemsPerPage: $itemsPerPage, pageNumber: $pageNumber) {
      SalesWines {
        wine_name
        distance
        so_pincode
        bid_status
        cellar_bk_id
        global_wine_id
        sale_id
        so_ask_price
        so_per_bottle_price
        so_crtd_date
        so_crtd_time
        so_highest_bid
        so_num_bids
        so_quantity
        so_status
        so_updt_date
        so_updt_time
        user_id
        user_wine_id
        cellar_wine {
          cellar_bk_id
          cellar_wine_df
          cw_ask_price
          cw_available_qty
          cw_bid_price
          cw_bottle_size
          cw_crtd_time
          cw_currency
          cw_image
          cw_lid_qlty
          cw_lid
          cw_liquid_lvl
          cw_packaging
          cw_price
          cw_quantity
          cw_sold_qty
          cw_storage
          cw_storage_temp
          cw_updt_time
          cw_vintage
          cw_description
          global_wine_id
          user_id
          user_wine_id
          global_wine {
            add_info1
            add_info2
            add_info3
            barrel
            color
            country
            cultivation
            currency
            current_price {
              average
            }
            date_added
            date_updated
            flavor
            format
            grape_variety
            id
            label
            label_quality
            lid
            lid_quality
            liquid_level
            location
            name
            packaging
            picture
            predicate_de
            predicate_fr
            producer_name
            producer_title
            production_quantity
            quantity
            region
            seller
            soil
            status
            storage
            storage_temperature
            sub_region
            sub_type
            type
            vineyard
            wine_type
            vintage {
              final
              first
            }
          }
          user {
            picture
            lastname
            identityId
            id
            firstname
            error
          }
        }
        seller_rank {
          seller_avg_rating
          seller_settled_trade
          seller_total_post
          seller_total_rank
          seller_total_wine
          seller_user_id
          seller_user_type
          sr_crtd_time
          sr_updt_time
        }
        user {
          vinoStatus
          error
          firstname
          id
          identityId
          lastname
          picture
          paymentPreference
          address
          companyName
          vatNumber
          userType
          user_level {
            user_level
          }
        }
      }
      pincodeDataStatus
      pageNumber
      itemsPerPage
      totalItems
    }
  }
`;

export const getAllMyBids = gql`
  query MyQuery {
    getCurrentUserBids {
      distance
      rating_status
      rating {
        bid_detail_id
        bid_rating_id
        buyer_user_id
        sale_offer_id
        sb_bid_comment
        sb_bid_header
        sb_bid_rating
        sb_crtd_time
        sb_updt_time
        seller_user_id
      }
      bd_ask_price
      bd_bid_price
      bd_crtd_date
      bd_crtd_time
      bd_quantity
      bd_status
      bd_updt_date
      bd_updt_time
      bid_detail_id
      bd_user_cellar {
        cellar_bk_crtd_time
        cellar_bk_df
        cellar_bk_id
        cellar_bk_nm
        cellar_bk_typ
        cellar_bk_updt_time
        totalCellarWines
        user_id
      }
      bd_cellar_bk_id
      buyer_user_id
      cellar_bk_id
      sale_offer_id
      user_wine_id
      seller_user_information {
        address
        error
        firstname
        id
        lastname
        mailID
        phone_number
        picture
        identityId
        companyName
        vatNumber
        userType
        user_level {
          user_level
        }
      }
      cellar_wine {
        cellar_bk_id
        cw_ask_price
        cw_available_qty
        cw_bid_price
        cw_currency
        cw_image
        cw_price
        cw_quantity
        cw_sold_qty
        cw_vintage
        cw_description
        user_id
        user_wine_id
        user {
          error
          firstname
          id
          identityId
          lastname
          picture
          companyName
          vatNumber
          userType
        }
        global_wine_id
        global_wine {
          color
          country
          currency
          id
          location
          name
          picture
          producer_title
          producer_name
          production_quantity
          quantity
          status
          sub_region
          sub_type
          type
          vintage {
            final
            first
          }
          wine_type
        }
      }
    }
  }
`;

export const getPostalCodesByParams = gql`
  query MyQuery($country: String!, $state: String!, $city: String!) {
    getPostalCodeByCity(country: $country, state: $state, city: $city) {
      pincodes
    }
  }
`;

export const getPostalCodesForNewUser = gql`
  query MyQuery($pincode: String!, $country: String!) {
    getPostalcodes(pincode: $pincode, country: $country) {
      pincode
    }
  }
`;

export const getCurrentUserVinoCoins = gql`
  query MyQuery {
    getCurrentUserVinoCoins {
      user_id
      vinoCoins
      functionalityCharges {
        currency_value
        func_cd
        validity
        vino_coin
      }
    }
  }
`;

export const getVcTransaction = gql`
  query MyQuery($startDate: String!, $endDate: String!) {
    getVcTransaction(
        startDate: $startDate,
        endDate: $endDate 
    ) {
      amount_in_eur
      cur_bal
      end_date
      func_cd
      prev_bal
      ref_field
      ref_id
      status
      timestamp
      transaction_id
      transaction_type
      user_id
      vino_coins
      start_date
      payment_method
      payment_error
      payment_reference
      payment_reference_number
      vat_amount_in_eur
      vat_amount
    }
  }
`;

export const searchSaleByWineName = gql`
  query MyQuery($name: String!) {
  searchSaleByWineName(name: $name) {
    cellar_bk_id
    distance
    global_wine_id
    settle_status
    sale_id
    wine_name
    userPincode
    user_id
    user_wine_id
    so_ask_price
    so_crtd_date
    so_crtd_time
    so_highest_bid
    so_num_bids
    so_per_bottle_price
    so_pincode
    so_quantity
    so_status
    so_updt_date
    so_updt_time
    so_vino_status
    bid_status
    user {
      vinoStatus
      address
      companyName
      error
      firstname
      id
      identityId
      lastname
      paymentPreference
      picture
      userType
      vatNumber
      user_level {
        user_level
      }
    }
    seller_rank {
      seller_avg_rating
      seller_settled_trade
      seller_total_post
      seller_total_rank
      seller_total_wine
      seller_user_id
      seller_user_type
      sr_crtd_time
      sr_updt_time
    }
    cellar_bk {
      cellar_bk_crtd_time
      cellar_bk_df
      cellar_bk_id
      cellar_bk_nm
      cellar_bk_typ
      cellar_bk_updt_time
      statistics {
        cellar_bk_id
        count
        sumCurrentValue
        sumInvestmentValue
      }
      totalCellarWines
      user_id
    }
    bids_user {
      buyer_user_id
    }
    cellar_wine {
      cellar_bk_id
      cellar_wine_df
      cw_ask_price
      cw_available_qty
      cw_bid_price
      cw_bottle_size
      cw_crtd_time
      cw_currency
      cw_image
      cw_lid_qlty
      cw_lid
      cw_liquid_lvl
      cw_packaging
      cw_price
      cw_quantity
      cw_sold_qty
      cw_storage
      cw_storage_temp
      cw_updt_time
      cw_vintage
      cw_description
      global_wine_id
      user_id
      user_wine_id
      global_wine {
        add_info1
        add_info2
        add_info3
        barrel
        color
        country
        cultivation
        currency
        current_price {
          average
        }
        date_added
        date_updated
        flavor
        format
        grape_variety
        id
        label
        label_quality
        lid
        lid_quality
        liquid_level
        location
        name
        packaging
        picture
        predicate_de
        predicate_fr
        producer_name
        producer_title
        production_quantity
        quantity
        region
        seller
        soil
        status
        storage
        storage_temperature
        sub_region
        sub_type
        type
        vineyard
        wine_type
        vintage {
          final
          first
        }
      }
      user {
        picture
        lastname
        identityId
        id
        firstname
        error
      }
    }
  }
}
`;

export const getSales = gql`
  query MyQuery($id: ID!){
    getSales(id: $id) {
      bid_status
      bids_user {
        buyer_user_id
      }
      cellar_bk {
        cellar_bk_crtd_time
        cellar_bk_df
        cellar_bk_id
        cellar_bk_nm
        cellar_bk_typ
        cellar_bk_updt_time
        statistics {
          cellar_bk_id
          count
          sumCurrentValue
          sumInvestmentValue
        }
        totalCellarWines
        user_id
      }
      cellar_bk_id
      distance
      global_wine_id
      sale_id
      seller_rank {
        seller_avg_rating
        seller_settled_trade
        seller_total_post
        seller_total_rank
        seller_total_wine
        seller_user_id
        sr_crtd_time
        seller_user_type
        sr_updt_time
      }
      settle_status
      so_ask_price
      so_crtd_date
      so_per_bottle_price
      so_quantity
      so_pincode
      so_num_bids
      so_highest_bid
      so_crtd_time
      so_status
      so_updt_date
      so_updt_time
      so_vino_status
      userPincode
      user_id
      user_wine_id
      user {
        vinoStatus
        address
        companyName
        error
        firstname
        id
        identityId
        lastname
        paymentPreference
        picture
        userType
        vatNumber
        user_level {
          user_level
        }
      }
      wine_name
      cellar_wine {
        cellar_bk_id
        cellar_wine_df
        cw_ask_price
        cw_available_qty
        cw_bid_price
        cw_bottle_size
        cw_crtd_time
        cw_currency
        cw_image
        cw_lid_qlty
        cw_lid
        cw_liquid_lvl
        cw_packaging
        cw_price
        cw_quantity
        cw_sold_qty
        cw_storage
        cw_storage_temp
        cw_updt_time
        cw_vintage
        cw_description
        global_wine_id
        user_id
        user_wine_id
        global_wine {
          add_info1
          add_info2
          add_info3
          barrel
          color
          country
          cultivation
          currency
          current_price {
            average
          }
          date_added
          date_updated
          flavor
          format
          grape_variety
          id
          label
          label_quality
          lid
          lid_quality
          liquid_level
          location
          name
          packaging
          picture
          predicate_de
          predicate_fr
          producer_name
          producer_title
          production_quantity
          quantity
          region
          seller
          soil
          status
          storage
          storage_temperature
          sub_region
          sub_type
          type
          vineyard
          wine_type
          vintage {
            final
            first
          }
        }
        user {
          picture
          lastname
          identityId
          id
          firstname
          error
        }
      }
    }
  }
`;

export const searchCurrentUserSaleByWineName = gql`
  query MyQuery($name: String!) {
  searchCurrentUserSaleByWineName(name: $name) {
    bid_status
    bids_user {
      buyer_user_id
    }
    cellar_bk {
      cellar_bk_crtd_time
      cellar_bk_df
      cellar_bk_id
      cellar_bk_nm
      cellar_bk_typ
      cellar_bk_updt_time
      statistics {
        cellar_bk_id
        count
        sumCurrentValue
        sumInvestmentValue
      }
      totalCellarWines
      user_id
    }
    cellar_bk_id
    distance
    global_wine_id
    sale_id
    settle_status
    seller_rank {
      seller_avg_rating
      seller_settled_trade
      seller_total_post
      seller_total_rank
      seller_total_wine
      seller_user_id
      seller_user_type
      sr_crtd_time
      sr_updt_time
    }
    so_ask_price
    so_crtd_date
    so_crtd_time
    so_highest_bid
    so_num_bids
    so_per_bottle_price
    so_pincode
    so_quantity
    so_status
    so_updt_date
    so_updt_time
    so_vino_status
    user {
      vinoStatus
      address
      companyName
      error
      firstname
      id
      identityId
      lastname
      paymentPreference
      picture
      userType
      vatNumber
      user_level {
        user_level
      }
    }
    userPincode
    user_id
    user_wine_id
    wine_name
    cellar_wine {
      cellar_bk_id
      cellar_wine_df
      cw_ask_price
      cw_available_qty
      cw_bid_price
      cw_bottle_size
      cw_crtd_time
      cw_currency
      cw_image
      cw_lid_qlty
      cw_lid
      cw_liquid_lvl
      cw_packaging
      cw_price
      cw_quantity
      cw_sold_qty
      cw_storage
      cw_storage_temp
      cw_updt_time
      cw_vintage
      cw_description
      global_wine_id
      user_id
      user_wine_id
      global_wine {
        add_info1
        add_info2
        add_info3
        barrel
        color
        country
        cultivation
        currency
        current_price {
          average
        }
        date_added
        date_updated
        flavor
        format
        grape_variety
        id
        label
        label_quality
        lid
        lid_quality
        liquid_level
        location
        name
        packaging
        picture
        predicate_de
        predicate_fr
        producer_name
        producer_title
        production_quantity
        quantity
        region
        seller
        soil
        status
        storage
        storage_temperature
        sub_region
        sub_type
        type
        vineyard
        wine_type
        vintage {
          final
          first
        }
      }
      user {
        picture
        lastname
        identityId
        id
        firstname
        error
      }
    }
  }
}
`;

export const searchCurrentUserBidsByWineName = gql`
  query MyQuery($name: String!) {
  searchCurrentUserBidsByWineName(name: $name) {
    bd_ask_price
    bd_bid_price
    bd_cellar_bk_id
    bd_crtd_date
    bd_crtd_time
    bd_quantity
    bd_status
    bd_updt_date
    bd_updt_time
    bid_detail_id
    bd_user_cellar {
      cellar_bk_crtd_time
      cellar_bk_df
      cellar_bk_id
      cellar_bk_nm
      cellar_bk_typ
      cellar_bk_updt_time
      statistics {
        cellar_bk_id
        count
        sumCurrentValue
        sumInvestmentValue
      }
      totalCellarWines
      user_id
    }
    buyer_pincode
    buyer_user_id
    buyer_user_information {
      address
      companyName
      error
      firstname
      id
      identityId
      lastname
      mailID
      phone_number
      userType
      picture
      vatNumber
      user_level {
        user_level
      }
    }
    cellar_bk_id
    distance
    rating {
      bid_detail_id
      bid_rating_id
      buyer_user_id
      sale_offer_id
      sb_bid_comment
      sb_bid_rating
      sb_crtd_time
      sb_updt_time
      seller_user_id
    }
    rating_status
    sale_offer_id
    seller_pincode
    seller_user_id
    seller_user_information {
      address
      companyName
      error
      firstname
      id
      identityId
      lastname
      picture
      userType
      vatNumber
      mailID
      phone_number
      user_level {
        user_level
      }
    }
    user_wine_id
    cellar_wine {
      cellar_bk_id
      cellar_wine_df
      cw_ask_price
      cw_available_qty
      cw_bid_price
      cw_bottle_size
      cw_crtd_time
      cw_currency
      cw_image
      cw_lid_qlty
      cw_lid
      cw_liquid_lvl
      cw_packaging
      cw_price
      cw_quantity
      cw_sold_qty
      cw_storage
      cw_storage_temp
      cw_updt_time
      cw_vintage
      cw_description
      global_wine_id
      user_id
      user_wine_id
      global_wine {
        add_info1
        add_info2
        add_info3
        barrel
        color
        country
        cultivation
        currency
        current_price {
          average
        }
        date_added
        date_updated
        flavor
        format
        grape_variety
        id
        label
        label_quality
        lid
        lid_quality
        liquid_level
        location
        name
        packaging
        picture
        predicate_de
        predicate_fr
        producer_name
        producer_title
        production_quantity
        quantity
        region
        seller
        soil
        status
        storage
        storage_temperature
        sub_region
        sub_type
        type
        vineyard
        wine_type
        vintage {
          final
          first
        }
      }
      user {
        picture
        lastname
        identityId
        id
        firstname
        error
      }
    }
  }
}
`;

export const getBid = gql`
  query MyQuery($bid_detail_id: ID!, $sale_offer_id: ID!) {
  getBid(bid_detail_id: $bid_detail_id, sale_offer_id: $sale_offer_id) {
    bd_ask_price
    bd_bid_price
    bd_cellar_bk_id
    bd_crtd_date
    bd_crtd_time
    bd_quantity
    bd_status
    bd_updt_date
    bd_updt_time
    bd_user_cellar {
      cellar_bk_nm
      cellar_bk_crtd_time
      cellar_bk_df
      cellar_bk_id
      cellar_bk_typ
      cellar_bk_updt_time
      statistics {
        cellar_bk_id
        count
        sumCurrentValue
        sumInvestmentValue
      }
      totalCellarWines
      user_id
    }
    bid_detail_id
    buyer_pincode
    buyer_user_id
    buyer_user_information {
      address
      companyName
      error
      firstname
      id
      identityId
      lastname
      mailID
      phone_number
      picture
      userType
      vatNumber
      user_level {
        user_level
      }
    }
    cellar_bk_id
    distance
    rating {
      bid_detail_id
      bid_rating_id
      buyer_user_id
      sale_offer_id
      sb_bid_comment
      sb_bid_rating
      sb_crtd_time
      sb_updt_time
      seller_user_id
    }
    rating_status
    sale_offer_id
    seller_pincode
    seller_user_id
    seller_user_information {
      address
      companyName
      error
      firstname
      id
      identityId
      lastname
      mailID
      phone_number
      picture
      userType
      vatNumber
      user_level {
        user_level
      }
    }
    user_wine_id
    cellar_wine {
      cellar_bk_id
      cellar_wine_df
      cw_ask_price
      cw_available_qty
      cw_bid_price
      cw_bottle_size
      cw_crtd_time
      cw_currency
      cw_image
      cw_lid_qlty
      cw_lid
      cw_liquid_lvl
      cw_packaging
      cw_price
      cw_quantity
      cw_sold_qty
      cw_storage
      cw_storage_temp
      cw_updt_time
      cw_vintage
      cw_description
      global_wine_id
      user_id
      user_wine_id
      global_wine {
        add_info1
        add_info2
        add_info3
        barrel
        color
        country
        cultivation
        currency
        current_price {
          average
        }
        date_added
        date_updated
        flavor
        format
        grape_variety
        id
        label
        label_quality
        lid
        lid_quality
        liquid_level
        location
        name
        packaging
        picture
        predicate_de
        predicate_fr
        producer_name
        producer_title
        production_quantity
        quantity
        region
        seller
        soil
        status
        storage
        storage_temperature
        sub_region
        sub_type
        type
        vineyard
        wine_type
        vintage {
          final
          first
        }
      }
      user {
        picture
        lastname
        identityId
        id
        firstname
        error
      }
    }
  }
}
`;

export const getUserAllRatings = gql`
  query MyQuery($sellerId: ID!) {
  getUserAllRatings(user_id: $sellerId) {
    ratings {
      bid_detail_id
      bid_rating_id
      buyer_user_id
      buyer_user_information {
        address
        companyName
        error
        firstname
        id
        identityId
        lastname
        mailID
        phone_number
        picture
        userType
        vatNumber
      }
      sale_offer_id
      sb_bid_header
      sb_bid_comment
      sb_bid_rating
      sb_crtd_time
      sb_updt_time
      seller_user_id
    }
    total_ratings
    user {
      companyName
      error
      firstname
      id
      identityId
      lastname
      picture
      userType
      vatNumber
    }
    user_id
    user_rank {
      seller_avg_rating
      seller_settled_trade
      seller_total_post
      seller_total_rank
      seller_total_wine
      seller_user_id
      seller_user_type
      sr_crtd_time
      sr_updt_time
    }
  }
}
`;

export const getUserLevelInfo = gql`
  query MyQuery {
    getUserLevelInformation {
      Level_1 {
        completion
        task
      }
      Level_2 {
        completion
        task
      }
      Level_3 {
        completion
        task
      }
      Level_4 {
        completion
        task
      }
      Level_5 {
        completion
        task
      }
      Level_6 {
        completion
        task
      }
      Level_7 {
        completion
        task
      }
      Level_8 {
        completion
        task
      }
      Level_9 {
        completion
        task
      }
      Level_10 {
        completion
        task
      }
      Level_11 {
        completion
        task
      }
      Level_12 {
        completion
        task
      }
      Level_13 {
        completion
        task
      }
      Level_14 {
        completion
        task
      }
      Level_15 {
        completion
        task
      }
      Level_16 {
        completion
        task
      }
      Level_17 {
        completion
        task
      }
      Level_18 {
        completion
        task
      }
      Level_19 {
        completion
        task
      }
      Level_20 {
        completion
        task
      }
      Level_21 {
        completion
        task
      }
      Level_22 {
        completion
        task
      }
      Level_23 {
        completion
        task
      }
      Level_24 {
        completion
        task
      }
      user_level
      user_id
      referralCount
      countOfWines
      countOfTotalOwnCommunities
      countOfTotalFiveRatingsToUser
      countOfSettleSales
      countOfSettleBids
      countOfPostsComment
      countOfSales
      countOfFriends
    }
  }
`;

export const getCellarbookGain = gql`
  query MyQuery($cellar_bk_id: ID!) {
    getCellarbookGain(cellar_bk_id: $cellar_bk_id) {
      cellar_bk_id
      realizedGainData {
        realizedGain
        sumOfSGPurchasePrice
        sumOfSalesPrice
      }
      unrealizedGainData {
        sumOfMarketPrice
        sumOfUSGPurchasePrice
        unrealizedGain
      }
    }
  }
`;

export const getDashboardNew = gql`
  query MyQuery {
    getDashboardNew {
      user_id
      highestAppreciationData {
          vino_status
          TopHighestAppreciation {
              bd_ask_price
              bd_bid_price
              bd_profit
              bd_quantity
              bid_detail_id
              buyer_user_id
              cellar_bk_id
              cw_price
              error
              global_wine_id
              sale_offer_id
              seller_user_id
              user_wine_id
              wine_name
              global_wine {
                  country
                  name
                  producer_name
                  producer_title
                  region
                  sub_region
                  type
                  wine_type
              }
              user {
                  address
                  companyName
                  error
                  firstname
                  id
                  identityId
                  lastname
                  picture
                  userType
                  vatNumber
              }
          }
      }
      lowestSpreadData {
          vino_status
          TopLowestSpread {
              cellar_bk_id
              cw_price
              error
              global_wine_id
              sale_id
              so_ask_price
              so_profit
              so_quantity
              user_id
              user_wine_id
              wine_name
              global_wine {
                  country
                  name
                  producer_name
                  producer_title
                  region
                  sub_region
                  type
                  wine_type
              }
              user {
                  address
                  companyName
                  error
                  firstname
                  id
                  identityId
                  lastname
                  picture
                  userType
                  vatNumber
              }
          }
      }
      oldestLowestPricesData {
          vino_status
          TopOldestLowestPrice {
              cellar_bk_id
              error
              global_wine_id
              sale_id
              so_ask_price
              user_id
              user_wine_id
              vintage
              wine_name
              yearDiffernce
              global_wine {
                  country
                  name
                  producer_name
                  producer_title
                  region
                  sub_region
                  type
                  wine_type
              }
              user {
                  address
                  companyName
                  error
                  firstname
                  id
                  identityId
                  lastname
                  picture
                  userType
                  vatNumber
              }
          }
      }
      youngestHighestPriceData {
        vino_status
        TopYoungestHighestPrice {
          cellar_bk_id
          error
          global_wine_id
          sale_id
          so_ask_price
          user_id
          user_wine_id
          vintage
          wine_name
          yearDiffernce
          global_wine {
            country
            name
            producer_name
            producer_title
            region
            sub_region
            type
            wine_type
          }
          user {
            address
            companyName
            error
            firstname
            id
            identityId
            lastname
            picture
            userType
            vatNumber
          }
        }
      }
    }
  }
`;

export const getOldestLowestPrice = gql`
  query MyQuery {
    getOldestLowestPrice {
      user_id
      TopOldestLowestPrice {
        cellar_bk_id
        cw_image
        error
        global_wine_id
        sale_id
        so_ask_price
        user_id
        user_wine_id
        vino_status
        vintage
        quantity
        global_wine {
          country
          name
          producer_name
          producer_title
          region
          sub_region
          type
          wine_type
        }
        wine_name
        yearDiffernce
        user {
          address
          companyName
          error
          firstname
          id
          identityId
          lastname
          picture
          userType
          vatNumber
          user_level {
            user_id
            user_level
          }
        }
      }
    }
  }
`;

export const getYoungestHighestPrice = gql`
  query MyQuery {
    getYoungestHighestPrice {
      user_id
      TopYoungestHighestPrice {
        cellar_bk_id
        cw_image
        error
        global_wine_id
        sale_id
        so_ask_price
        quantity
        global_wine {
          country
          name
          producer_name
          producer_title
          region
          sub_region
          type
          wine_type
        }
        user_id
        user {
          address
          companyName
          error
          firstname
          id
          identityId
          lastname
          picture
          userType
          vatNumber
          user_level {
            user_id
            user_level
          }
        }
        user_wine_id
        vino_status
        vintage
        wine_name
        yearDiffernce
      }
    }
  }
`;

export const getLowestSpread = gql`
  query MyQuery {
    getLowestSpread {
      user_id
      TopLowestSpread {
        cellar_bk_id
        cw_image
        cw_price
        error
        global_wine {
          country
          name
          producer_name
          producer_title
          region
          sub_region
          type
          wine_type
        }
        global_wine_id
        sale_id
        so_ask_price
        so_profit
        so_quantity
        spread
        user_id
        user_wine_id
        vino_status
        vintage
        wine_name
        yearDiffernce
        quantity
        user {
          address
          companyName
          error
          firstname
          id
          identityId
          lastname
          picture
          userType
          vatNumber
          user_level {
            user_id
            user_level
          }
        }
      }
    }
  }
`;

export const getHighestAppreciation = gql`
  query MyQuery {
    getHighestAppreciation {
      user_id
      TopHighestAppreciation {
        appreciation
        bd_ask_price
        bd_bid_price
        bd_profit
        bd_quantity
        bid_detail_id
        buyer_user_id
        cellar_bk_id
        cw_image
        cw_price
        error
        global_wine_id
        sale_offer_id
        seller_user_id
        user_wine_id
        vino_status
        vintage
        wine_name
        yearDiffernce
        quantity
        user {
          address
          companyName
          error
          firstname
          id
          identityId
          lastname
          picture
          userType
          vatNumber
          user_level {
            user_id
            user_level
          }
        }
        global_wine {
          country
          name
          producer_name
          producer_title
          region
          sub_region
          type
          wine_type
        }
      }
    }
  }
`;

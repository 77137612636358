<div class="faq-container">
    <div class="container">
        <h3 class="heading text-center mb-4 mb-md-4">Frequently asked Questions</h3>
        <div class="search-container">
            <input type="text" class="form-control faq-search-input" (keyup)="filterFaqList($event)" placeholder="Search FAQ">
        </div>
        <div class="faq-wrapper">
            <div class="faq" *ngFor="let faq of faqList; let i = index;">
                <div class="question">
                    <div class="text">{{ faq.question }}</div>
                    <div class="icon" (click)="toggleAccordion(i)">
                        <mat-icon *ngIf="activeIndex !== i">add_2</mat-icon>
                        <mat-icon *ngIf="activeIndex === i">remove</mat-icon>
                    </div>
                </div>
                <div class="answer secondary-color" *ngIf="activeIndex === i" [innerHTML]="faq.answer"></div>
            </div>
            <h4 class="no-match text-center" *ngIf="!faqList.length">{{ 'No Match Found' }}</h4>
        </div>
    </div>
</div>
import { Component, Injectable, OnInit, Output, EventEmitter } from '@angular/core';
import { Stripe } from '@stripe/stripe-js';
import { AuthenticationService } from '../sharedServices/authentication.service';
import { Auth } from 'aws-amplify';
import { TranslateService } from '@ngx-translate/core';
import { StripePaymentService } from './stripe-payment.service';
import { catchError, retry } from 'rxjs';
import HttpService from '../sharedServices/http.service';
import LoadingIndicatorService from 'src/app/sharedServices/loading-indicator.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UserProfileDataService } from '../user-profile/user-profile-data.service';
import { environment } from 'src/environments/environment';


declare const Stripe: (arg0: string) => any;

@Component({
  selector: 'app-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.css']
})

@Injectable({
  providedIn: 'root'
})

export class StripePaymentComponent implements OnInit {
  @Output() onClosePayment = new EventEmitter();
  exclVatVinoTotal = 0.00;
  inclVatVinoTotal = 0.00;
  langCode: string = 'EN';
  inclVatStatement: string = "";
  vcCalculationStatement = "";
  userType = "Private User";
  vcPrice: number = 0;
  vcCharges: number = 0;
  vatPercentage: number = 0;
  viewBidChargesStatement: string = "";
  currentVcPriceStatement: string = "";
  stripeUrlVal = null
  stripe: any;
  user: any = {};
  friendStripeAccId: string;
  purchaseVinoForm = this.fb.group({
    vinoCoins: ['', [Validators.required, Validators.pattern("^[0-9]+$")]],
  });

  constructor(
    private data: AuthenticationService,
    public translate: TranslateService,
    private apiService: StripePaymentService,
    private httpService: HttpService,
    private fb: FormBuilder,
    private loadingIndicator: LoadingIndicatorService,
    private userProfileDataService: UserProfileDataService
  ) { 
    this.data.currentUser.subscribe((user) => (this.user = user));
  };

  ngOnInit(): void {
    this.userType = this.user.attributes['custom:userType'];
    this.translate.onLangChange.subscribe((data) => {
      this.langCode = data.lang;
      this.createChargesStatement();
    });
    const chargesCollection = this.userProfileDataService.getFunctionalityCharges();
    if (chargesCollection) {
      this.vcPrice = chargesCollection.VinoCoinValue;
      this.vatPercentage = chargesCollection.VatPercentage;
      this.vcCharges = chargesCollection.SBV01;
    }
    this.createChargesStatement();
  }

  onVinoChange() {
    this.exclVatVinoTotal = (Math.round((Number(this.purchaseVinoForm.get('vinoCoins').value) + Number.EPSILON) * 100) / 100) * this.vcPrice;
    this.inclVatVinoTotal = Math.round(((this.exclVatVinoTotal + (this.exclVatVinoTotal / 100 * Number(this.vatPercentage)) + Number.EPSILON)) * 100) / 100;
  }

  async onPayment() {
    let completed = await this.redirectToCheckout(Number(this.inclVatVinoTotal.toFixed(2)), this.purchaseVinoForm.get('vinoCoins').value);
    return;
  }

  onCancel() {
    this.onClosePayment.emit();
    this.purchaseVinoForm.get('vinoCoins').reset();
  }

  async redirectToCheckout(amount: any, vinoQty: any) {
    try{
      const stripe = require('stripe')(environment.payment.stripe.secretKey);
      const session = stripe.checkout.sessions.create({
        mode: 'payment',
        // payment_method_types: ['paypal', 'card'],
        line_items: [
          {
            price_data: {
              product_data: {
                name: "Amount", 
              },
              unit_amount_decimal: Number((amount * 100).toFixed(2)),
              currency: "usd", 
            },
            quantity: 1, 
          }
        ],
        payment_intent_data: {
          // application_fee_amount: 1 * 100, 
          transfer_data: {
            destination: "acct_1O5mMBJrYCzJqzYZ"
          },
        },
        // payment_method_configuration: 'pmc_1NyDkaCteHrC7wpHtCZ2Tz6z',
        cancel_url: window.location.href,
        success_url: window.location.href
      });
      const checkout = Stripe(environment.payment.stripe.publishableKey);
      session.then((checkoutSession: { id: any; }) => {
        let sessionDetails = {"id": checkoutSession.id, "vinoQty": vinoQty, "amountTotal": amount};
        localStorage.setItem("paymentSession", JSON.stringify(sessionDetails));
        checkout.redirectToCheckout({ sessionId: checkoutSession.id })
      });
    } catch (error) {
      alert("Internal Server Error!");
    }
    return true;
  }

  async getPaymentStatus(paymentSession: any, currentVc: any) {
    var paymentStatus = false;
    const functionalityCharge = this.userProfileDataService.getFunctionalityCharges();
    let purchasedVino = paymentSession.vinoQty;
    let purchasedVinoInEur = Number((purchasedVino * functionalityCharge.VinoCoinValue).toFixed(2));
    let purchasedVinoOnVat = Number((purchasedVinoInEur / 100 * functionalityCharge.VatPercentage).toFixed(2));
    var paymentDetailsRequestBody: any = {
      "amount_in_eur": purchasedVinoInEur, // without vat amount in float
      "vat_amount": purchasedVinoOnVat, //in float
      "func_cd": "STP001", 
      "payment_id": "",
      "prev_bal": currentVc,
      "transaction_type": "credit",
      "status": "",
      "payment_method": "",
      "payment_error": "You have canceled this payment.",
      "payment_reference": "",
      "payment_reference_number": "",
      "vat_amount_in_eur": "" // with vat amount
    };
    try {
      const StripePaymentInfo = await this.apiService.getSessionDetails(paymentSession.id).toPromise();
      paymentDetailsRequestBody.amount = String(StripePaymentInfo.amount_total / 100);
      paymentDetailsRequestBody.vat_amount_in_eur = StripePaymentInfo.amount_total / 100;
      if (StripePaymentInfo.payment_intent) {
        const StripePaymentReferenceInfo = await this.apiService.getPaymentReferenceDetails(StripePaymentInfo.payment_intent).toPromise();
        paymentDetailsRequestBody.payment_id = StripePaymentReferenceInfo.id;
        if (StripePaymentReferenceInfo.last_payment_error) {
          paymentDetailsRequestBody.payment_method = StripePaymentReferenceInfo.last_payment_error.payment_method.type;
          paymentDetailsRequestBody.payment_reference = StripePaymentReferenceInfo.last_payment_error.payment_method[paymentDetailsRequestBody.payment_method]?.brand ? StripePaymentReferenceInfo.last_payment_error.payment_method[paymentDetailsRequestBody.payment_method].brand : "";
          paymentDetailsRequestBody.payment_reference_number = StripePaymentReferenceInfo.last_payment_error.payment_method[paymentDetailsRequestBody.payment_method]?.last4 ? StripePaymentReferenceInfo.last_payment_error.payment_method[paymentDetailsRequestBody.payment_method].last4 : "";
          paymentDetailsRequestBody.payment_error = StripePaymentReferenceInfo.last_payment_error.message
        } else {
          const StripePaymentMethodInfo = await this.apiService.getPaymentMethodDetails(StripePaymentReferenceInfo.payment_method).toPromise();
          paymentDetailsRequestBody.payment_method = StripePaymentMethodInfo.type;
          paymentDetailsRequestBody.payment_reference = StripePaymentMethodInfo[paymentDetailsRequestBody.payment_method]?.brand ? StripePaymentMethodInfo[paymentDetailsRequestBody.payment_method].brand : "";
          paymentDetailsRequestBody.payment_reference_number = StripePaymentMethodInfo[paymentDetailsRequestBody.payment_method]?.last4 ? StripePaymentMethodInfo[paymentDetailsRequestBody.payment_method].last4 : "";
        }
      }
      if (StripePaymentInfo.status == "complete") {
        paymentStatus = true;
        paymentDetailsRequestBody.status = "successful";
        paymentDetailsRequestBody.payment_error = "";
      } else {
        paymentDetailsRequestBody.status = "failed";
      }
      var response = await this.apiService.setPaymentDetails(paymentDetailsRequestBody).toPromise();
      this.userProfileDataService.storeVinoCoins(response.data?.addVcTransactionHistory?.cur_bal);
    } catch (error) {
      console.log("error : ", error);
    }
    return paymentStatus
  }

  createChargesStatement() {
    if (this.userType == "Business User") {
      this.vcCalculationStatement = this.translate.instant('business user vc price statement').replace("1$", String(this.vcPrice));
      this.inclVatStatement = this.translate.instant('incl_vat').replace("1$", String(this.vatPercentage));
    } else {
      var statement = this.translate.instant('private user vc price statement');
      statement = statement.replace("1$", String(this.vcPrice));
      this.vcCalculationStatement = statement.replace("2$", String(this.vatPercentage));
    }
    this.viewBidChargesStatement = this.translate.instant('bid purchase btn txt').replace("1$", String(this.vcCharges));
  }

  // below code is not currently in use


  createConnectedAccountOnStripe(userDetail: any) {
    const paymentInfo = JSON.parse(this.user.attributes['custom:paymentInfo']);
    const stripe = require('stripe')("sk_test_51Nt5bGCteHrC7wpHVxVtamYc98cqfgfU9SqgxnWIUXJUPFoWBIYFfRe3EpplAsP6jCrZAaZQrNDJErDvMg2tTk4X00SgN5HOgo");

    /* On Boarding button logic here */

    if (paymentInfo.hasOwnProperty("boardingAccountId") && paymentInfo.boardingAccountId != 'NA') {
      const accountLinkobj = stripe.accountLinks.create({
        account: paymentInfo.boardingAccountId,
        refresh_url: window.location.href,
        return_url: window.location.href,
        type: 'account_onboarding',
      });
      accountLinkobj.then((data: { url: string; }) => {
        window.open(data.url, "_self");
      })
      return true;
    }

    const account = stripe.accounts.create({
      type: 'custom',
      individual: {
        first_name: userDetail.firstName.value,
        last_name: userDetail.lastName.value,
        phone: userDetail.phoneNumber.value,
        address: {
          city: userDetail.city.value,
          country: userDetail.country.value,
          postal_code: userDetail.postalCode.value,
          state: userDetail.state.value,
          line1: userDetail.street.value
        }
      },
      business_type: "individual",
      country: userDetail.country.value,
      email: userDetail.email.value,
      capabilities: {
        card_payments: { requested: true },
        transfers: { requested: true },
      },
    }).then((data: any) => {
      paymentInfo['boardingAccountId'] = data.id;
      const accountLinkobj = stripe.accountLinks.create({
        account: data.id,
        refresh_url: window.location.href,
        return_url: window.location.href,
        type: 'account_onboarding',
      }).then((data: any) => {
        window.open(data.url, "_self");
        Auth.updateUserAttributes(this.user, {
          'custom:paymentInfo': JSON.stringify(paymentInfo),
        })
      }).catch((err: any) => {
        alert(err);
        return false;
      });
    }).catch((err: any) => {
      alert(err);
      return false;
    });
    return true;
  }

  async getCurrentUserStatus(): Promise<any> {
    try {
      var accountStatus = "Your on-boarding process is pending. Click on below button and complete it";
      var accountCompleted = false;
      const paymentInfo = JSON.parse(this.user.attributes['custom:paymentInfo']);
      if (paymentInfo.hasOwnProperty("boardingAccountId") && paymentInfo.boardingAccountId != 'NA') {
        try {
          const data = await this.apiService.getCustomerAccount(paymentInfo.boardingAccountId).toPromise();
          if (data.details_submitted) {
            accountStatus = "You have successfully on-boarded.";
            accountCompleted = true;
          } else {
            accountStatus = "Your on-boarding account is incompleted. Click on below button and complete it";
          }
        } catch (error) {
          alert("Issue in your stripe account.")
        }
      }
      return [accountStatus, accountCompleted];
    } catch (error) {
      alert ("Internal Server Error!");
    }
    return ["", false];
  }

  async getCurrentUserDetials(): Promise<any> {
    try {
      const paymentInfo = JSON.parse(this.user.attributes["custom:paymentInfo"]);
      const data = await this.apiService.getCustomerAccount(paymentInfo.boardingAccountId).toPromise();
      return data;
    } catch (error) {
      alert("Issue in your stripe account.")
    }
    return {};
  }
  

  async checkYourFriendIsOnboarded(friendId: string){
    var friendAccountStatus = {"status": false, "message" : "Your friend does not exist in Treservino!"};
    try { 
      var friendStripeId = await new Promise<boolean>((resolve) => {
        this.apiService.getCustomerCognitoData(friendId)
          .valueChanges.subscribe(
            ({ data }) => {
              resolve(data)
            },
            (error) => {
              resolve(false);
            }
          );
      });
      if (friendStripeId) {
        if (friendStripeId["getStripeID"].user_id == friendId) {
          if (friendStripeId["getStripeID"].stripe_id) {
            try {
              const data = await this.apiService.getCustomerAccount(friendStripeId["getStripeID"].stripe_id).toPromise();
              if (data.details_submitted) {
                this.friendStripeAccId = friendStripeId["getStripeID"].stripe_id;
                friendAccountStatus.status = true;
                friendAccountStatus.message = "Your friend is on-boarded!";
              } else {
                friendAccountStatus.message = "Your friend stripe account is incomplete!";
              }
            } catch (error) {
              friendAccountStatus.message = "Your friend stripe account is invalid!";
            }
          } else {
            friendAccountStatus.message = "Your friend is not on-boarded!";
          }
        } 
      }
    } catch (error) {
      alert("Internal Server Error");
    }
    return friendAccountStatus;
  }
}


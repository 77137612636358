import { gql } from 'apollo-angular';

export const getOwnCommunitiesQuery = gql`
query MyQuery {
  getOwnCommunities {
    communities {
      community {
        community_name
        community_location
        created_by
        creator {
          error
          firstname
          id
          identityId
          lastname
          picture
          companyName
          userType
          vatNumber
        }
        description
        id
        is_public
        members {
          total
          users {
            comments_allowed
            posts_allowed
            is_admin
            joined_timestamp
            membership_status
            user_id
            user {
              error
              firstname
              lastname
              id
              identityId
              picture
              userType
              companyName
              vatNumber
            }
          }
        }
        picture
        updated_timestamp
        created_timestamp
      }
      membership_status
      joined_timestamp
      community_id
      is_admin
    }
  }
}`;

export const getAllCommunitiesQuery = gql`
query MyQuery {
  getAllCommunities {
    communities {
      community_name
      created_by
      members {
        total
        users {
          membership_status
          user {
            firstname
            lastname
            error
            id
            identityId
            picture
          }
          user_id
          is_admin
          joined_timestamp
        }
        nextToken
      }
      community_location
      created_timestamp
      creator {
        firstname
        lastname
        error
        id
        identityId
        picture
      }
      id
      description
      updated_timestamp
      is_public
      picture
    }
  }
}`;

export const searchCommunityQuery = gql`
query MyQuery($name: String!){
    searchCommunities(name: $name){
    id
    community_name
    description
    picture
    community_location
    is_public
    created_by
    creator {
        id
        identityId
        firstname
        lastname
        picture
        error
    }
    members {
        nextToken
        total
        users {
            user_id
            user {
                id
                identityId
                firstname
                lastname
                picture
                error
            }
            joined_timestamp
            is_admin
        }
    }
    created_timestamp
    updated_timestamp
  }
}`;

export const getCommunityQuery = gql`
query MyQuery($id: ID!){
  getCommunity(id: $id){
    id
    community_name
    description
    picture
    community_location
    is_public
    created_by
    creator {
      id
      identityId
      firstname
      lastname
      picture
      error
    }
    members {
      nextToken
      total
      users {
        user_id
        user {
          id
          identityId
          firstname
          lastname
          picture
          error
          companyName
          vatNumber
          userType
        }
        membership_status
        joined_timestamp
        is_admin
        posts_allowed
        comments_allowed
      }
    }
    created_timestamp
    updated_timestamp
  }
}`;


export const getOwnCommunitiesListQuery = gql`
query MyQuery {
    getOwnCommunities{
    nextToken
    communities {
      community_id
      is_admin
    }
  }
}`;

export const getCommunityPostQuery = gql`
query MyQuery($community_id: ID!) {
  getPosts(community_id: $community_id) {
    posts {
      user {
        error
        firstname
        id
        identityId
        lastname
        picture
        companyName
        userType
        vatNumber
        user_level {
          user_level
        }
      }
      body
      community_id
      created_by
      created_timestamp
      created_timestamp_day
      image
      post_id
      updated_timestamp
      updated_timestamp_day
    }
  }
}`;

export const getPostReactions = gql`
query MyQuery($post_id: ID!) {
  getPostReactions(post_id: $post_id) {
    reactions {
      community_id
      post_id
      reaction_type
      timestamp
      timestamp_day
      user_id
      user {
        firstname
        id
        identityId
        lastname
        picture
      }
    }
  }
}`;

export const getPostComments = gql`
query MyQuery($count: Int, $nextToken: String, $post_id: ID!) {
  getComments(post_id: $post_id, nextToken: $nextToken, count: $count) {
    comments {
      body
      comment_id
      community_id
      created_by
      created_timestamp
      created_timestamp_day
      post_id
      updated_timestamp
      updated_timestamp_day
      user {
        picture
        lastname
        id
        identityId
        firstname
        error
        companyName
        vatNumber
        userType
        user_level {
          user_level
        }
      }
    }
    nextToken
  }
}`;

export const getCommentReactions = gql`
query MyQuery($comment_id: ID!) {
  getCommentReactions(comment_id: $comment_id) {
    reactions {
      comment_id
      reaction_type
      timestamp
      timestamp_day
      user_id
      user {
        firstname
        id
        identityId
        lastname
        picture
        error
        companyName
        userType
        vatNumber
      }
    }
  }
}`;

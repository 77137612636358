<footer>
    <div class="footer-one d-flex flex-column flex-md-row">
        <div class="left-side">
            <img src="../assets/img/TresorVino-Logo-white.png" class="footer-logo">
        </div>
        <div class="right-side d-flex flex-column">
            <div class="section-one">
                <span class="font-bold heading">About Us</span>
                <p class="m-0 mt-3">TresorVino connects wine enthusiasts on a peer-to-peer platform for seamless buying, selling, and showcasing of rare and fine wines. With tools like the Digital Cellar Book and Vino Coins, we make wine trading simple, transparent, and rewarding. Join our global community and turn your passion into profit.</p>
            </div>
            <div class="section-two d-flex justify-content-between justify-content-md-start">
                <div class="links d-flex flex-column mr-2 mr-md-5">
                    <span class="heading font-bold">Links</span>
                    <span><a routerLink="/privacy-policy">Privacy</a></span>
                    <span><a routerLink="/terms-and-conditions">Terms</a></span>
                    <span><a routerLink="/imprint">Disclaimer</a></span>
                </div>
                <div class="follow-us d-flex flex-column mr-2 mr-md-5">
                    <span class="heading font-bold">Follow Us</span>
                </div>
                <div class="contact-us d-flex flex-column">
                    <span class="heading font-bold">Contact Us</span>
                    <span>
                        Tresorvino<br>
                        Baroxx Projektmanagement GmbH<br>
                        HRB 722280 (Amtsgericht Mannheim)<br>
                        Am Büchsenackerhang 66
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-two">
        Copyright Text
    </div>
</footer>
<head>
  <!-- Taboola Pixel Code -->
  <script type='text/javascript'>
    window._tfa = window._tfa || [];
    window._tfa.push({notify: 'event', name: 'page_view', id: 1733058});
    !function (t, f, a, x) {
          if (!document.getElementById(x)) {
              t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
          }
    }(document.createElement('script'),
    document.getElementsByTagName('script')[0],
    '//cdn.taboola.com/libtrc/unip/1733058/tfa.js',
    'tb_tfa_script');
  </script>
  <!-- End of Taboola Pixel Code -->
</head>
<nav class="navbar navbar-dark navbar-expand-lg tr-header myHeader justify-content-lg-start d-flex align-items-center" [ngClass]="{'show': showMenu, 'tr-shadow align-items-md-end': authStatus}">
  <button class="navbar-toggler mr-lg-3" *ngIf="authStatus" type="button" (click)="showHideMenu()">
    <!-- <i class="fa fa-bars" aria-hidden="true"></i> -->
    <mat-icon aria-label="Menu Icon" *ngIf="!showMenu">menu</mat-icon>
    <mat-icon aria-label="Close Icon" *ngIf="showMenu">close</mat-icon>
    <!-- <i class="fa fa-times" aria-hidden="true"></i> -->
  </button>

  <a class="navbar-brand" *ngIf="authStatus" routerLink="/dashboard">
    <img src="../../assets/img/TresorVino-Logo-white.png" alt="Tresorvino Logo">
  </a>

  <div class="collapse navbar-collapse tr-primary-menu" *ngIf="authStatus" [ngClass]="{'show': showMenu}">
    <ul class="navbar-nav mr-lg-auto">
      <li class="nav-item" routerLinkActive="active">
          <a class="nav-link d-flex align-items-center" routerLink="/dashboard">
            {{ 'common.dashboard' | translate }}
          </a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link d-flex align-items-center" routerLink="/cellarbook">
          {{ 'common.cellar_book' | translate }}
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link d-flex align-items-center" routerLink="/friends" [fragment]="'friendList'">
          {{ 'common.friends' | translate }}
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link d-flex align-items-center" routerLink="/communities">
          {{ 'common.communities' | translate }}
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link d-flex align-items-center" routerLink="/marketplace" [fragment]="'offers'">
          {{ 'common.marketplace' | translate }}
        </a>
      </li>
    </ul>
  </div>

  <div class="top-logo" *ngIf="!authStatus">
    <a [routerLink]="['/home']">
      <img class="main-logo" src="../../assets/img/TresorVino-Logo-white.png" alt="Tresorvino Logo">
    </a>
  </div>

  <ul class="navbar ml-lg-auto tr-secondary-menu" [ngClass]="{'d-none d-lg-flex': showMenu}">
    <li class="nav-item">
      <div class="lang-drop" tooltip="{{ 'header.select_language' | translate }}">
        <mat-form-field appearance="fill" [ngClass]="'white-field'">
          <mat-select  #selectedLang [ngModel]="userLang" panelClass="white-field" (selectionChange)="switchLang(selectedLang.value)">
            <mat-option *ngFor="let language of translate.getLangs()" [value]="language"
            > {{ language }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </li>
    <li class="nav-item" *ngIf="authStatus">
      <a routerLink="/profile" [fragment]="'payment'">
        <app-vino-coin-balance [size]="'small'"></app-vino-coin-balance>
      </a>
    </li>
    <li class="nav-item" *ngIf="authStatus">
      <app-notifications></app-notifications>
    </li>
    <li class="nav-item mr-2" *ngIf="!authStatus && !isLoginPage">
      <button class="btn tr-btn-primary btn_style" routerLink="/login">
        {{ 'common.login' | translate }}
      </button>
    </li>
    <li class="nav-item" *ngIf="!onRegister && !authStatus && isFirstTime">
      <button class="btn tr-btn-primary btn_style wave-button" routerLink="/registration">
        {{registerText}}<!-- {{ 'common.register' | translate }} -->
      </button>
    </li>
    <li class="nav-item profile-menu" *ngIf="authStatus">
      <div class="dropdown">
        <a class="my-2 my-sm-0" role="button">
          <img src={{imageUrl}} alt="Photo" class="profileImage" [ngClass]="{'businessProfileImage' : isBusinessUser }" type="button">
          <div class="level-info-star font-bold" [ngClass]="{ 'two-digit-level': userLevelInfo?.user_level > 9 }" (click)="showHideLevelProgressOverlay()">
            <img src="../../../../assets/img/level-star.svg" class="level-star" alt="level star">
            <span class="level-text">LV<span class="level-number">{{ userLevelInfo?.user_level }}</span></span>
          </div>
          <span class="user-profile hidden-content" [tooltip]="firstName.length > 15 ? firstName : ''" (click)="showHideProfileMenu()">
            {{ firstName.length > 15 ? (firstName | slice:0:15) + '...' : firstName }}
          </span>
          <span class="user-profile show-content" [tooltip]="firstName.length > 10 ? firstName : ''" (click)="showHideProfileMenu()">
            {{ firstName.length > 10 ? (firstName | slice:0:10) + '...' : firstName }}
          </span>
        </a> 

        <div class="profile-dropdown tr-bg-fourth" (click)="showProfileMenu=false" (clickOutside)="showProfileMenu=false" [exclude]="'.user-profile'" *ngIf="showProfileMenu">
          <a class="dropdown-item" routerLink="/profile" [fragment]="'personalDetails'">
            <mat-icon>account_circle</mat-icon>
            <span class="userOptions">{{ 'common.my_profile' | translate }}</span>
          </a>
          <a class="dropdown-item" routerLink="/settings">
            <mat-icon>settings</mat-icon>
            <span class="userOptions">{{ 'common.settings' | translate }}</span>
          </a>
          <a class="dropdown-item" routerLink="/home" (click)="onLogOut()">
            <mat-icon>logout</mat-icon>
            <span class="userOptions">{{ 'common.log_out' | translate }}</span>
          </a>
        </div>
        <div class="user-level-progress d-flex flex-column align-items-center" (clickOutside)="showLevelProgress=false" [exclude]="'.level-info-star'" *ngIf="showLevelProgress">
          <span class="close-btn" (click)="showHideLevelProgressOverlay()">
            <mat-icon>close</mat-icon>
          </span>
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="level-info" *ngFor="let level of allLevelDetails; let i = index" [ngClass]="{ 'd-none': challengeIndex !== i, 'd-flex align-items-center': challengeIndex === i }">
              <span class="navigate-left" (click)="prevChallenge()">
                <mat-icon [ngClass]="{ 'arrow-disabled': i === 0 }">chevron_left</mat-icon>
              </span>
              <div class="d-flex flex-column">
                <h6 class="m-0" *ngIf="i === 23 && level.completion != 100">{{ 'common.final_level_text' | translate }}</h6>
                <h6 class="m-0" *ngIf="i === 23 && level.completion === 100">{{ 'common.you_reached_final_level' | translate }}</h6>
                <div class="challenge-info mb-3 font-bold" *ngIf="i < 23">
                  {{ 'common.next_challenge' | translate }} {{ allLevelDetails[i+1].task }}
                </div>
                <div class="completion-status d-flex align-items-center mb-2" *ngIf="i < 23">
                  <img class="gray-star" src="../../../../assets/img/next-level-badge-gray.svg" *ngIf="level.completion != 100">
                  <img class="golden-star" src="../../../../assets/img/level-star.svg" *ngIf="level.completion == 100">
                  <span class="level-text" [ngClass]="{ 'two-digit-level' : level.level > 9 }">LV<span class="font-bold">{{ level.level }}</span></span>
                  <div class="progress">
                    <span class="status" [ngStyle]="{ 'width': level.completion + '%' }"></span>
                    <span class="status-text">{{ level.completion + '%' }}</span>
                  </div>
                </div>
              </div>
              <span class="navigate-right" (click)="nextChallenge()">
                <mat-icon [ngClass]="{ 'arrow-disabled': i === 23 }">chevron_right</mat-icon>
              </span>
            </div>
          </div>
          <div class="pagination d-flex align-align-center">{{ challengeIndex + 1 }}/24</div>
        </div>
      </div>
    </li>
  </ul>
</nav>
